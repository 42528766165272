import { chooseLayout } from 'slices/layout/layout.slices'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { L } from 'slices/layout/layout.interfaces'

const useLayout = (layout: L) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(chooseLayout(layout))
  }, [])
}

export default useLayout
