import { AuthState, Login } from './auth.interfaces'
import {
  createAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'

import JWTDecode from 'jwt-decode'
import { TokenPayload } from '@types'
import { get, save } from 'services/requests'

export const fetchLogin = createAsyncThunk(
  'auth/fetchLogin',
  async (loginData: Login) => await
    save<any>('token', {
      email: loginData.email,
      password: loginData.password,
      grantType: 'password',
      system: 'Marketplace',
    })
)

const fetchIpTest = createAsyncThunk('auth/test', async () =>
  get('api/user/ip')
)

const logout = createAction('auth/logout')

export const initialState: AuthState = {
  status: 'NotAsked',
  accessToken: '',
  expiresIn: 0,
  userId: 0,
  email: '',
  name: '',
  cnpjcpf: '',
  corporateName: '',
  emailConfirmed: false,
  cellphone: '',
  telephone: '',
  products: []
}


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuth: () => initialState
  },
  extraReducers(builder) {
    builder.addCase(fetchLogin.pending, (state) => {
      state.status = 'Pending'
      state.accessToken = ''
      state.expiresIn = 0
      state.userId = 0
      state.email = ''
      state.name = ''
      state.cnpjcpf = ''
      state.corporateName = ''
      state.emailConfirmed = false
      state.cellphone = ''
      state.telephone = ''
      state.products = []
      state.tokenPayload = undefined

    })

    builder.addCase(fetchLogin.rejected, (state) => {
      state.status = 'Error'
      state.status = 'Pending'
      state.accessToken = ''
      state.expiresIn = 0
      state.userId = 0
      state.email = ''
      state.name = ''
      state.cnpjcpf = ''
      state.corporateName = ''
      state.emailConfirmed = false
      state.cellphone = ''
      state.telephone = ''
      state.products = []
      state.tokenPayload = undefined
    })

    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.status = 'Done'
      state.accessToken = action.payload.accessToken
      state.expiresIn = action.payload.expiresIn
      state.userId = action.payload.userId
      state.email = action.payload.email
      state.name = action.payload.name
      state.cnpjcpf = action.payload.cnpjcpf
      state.corporateName = action.payload.corporateName
      state.emailConfirmed = action.payload.emailConfirmed
      state.cellphone = action.payload.cellphone
      state.telephone = action.payload.telephone
      state.products = action.payload.products
      state.tokenPayload = JWTDecode(
        action.payload.accessToken
      ) as TokenPayload
    })

  }
})
export { logout, fetchIpTest }

export const { clearAuth } = authSlice.actions

export default authSlice.reducer
