import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { save } from "services";
import { Product, ProductState } from "./product.interface";

export const initialState: ProductState = {
  items: {},
  errors: {},
  status: {
    page: "NotAsked",
  },
};


export const getProduct = createAsyncThunk(
  "product/getProduct",
  async (body: { productKey: string, cars: string[],isBatch:boolean }) => {
    try {
      const response = await save<any>(`api/product/${body.productKey}`, {cars:body.cars,isBatch:body.isBatch});
      return response;
    } catch (error) {
      return error;
    }
  }
);

const adapter = createEntityAdapter<Product>();

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearProduct: () => initialState,
    resetStatus: (state) => {
      state.status["page"] = "NotAsked";
    },
  },
  extraReducers(builder) {
    builder.addCase(getProduct.pending, (state,action) => {
      state.status["page"] = "Pending";
      state.items[action.meta.arg.productKey]= {} as Product;
    });

    builder.addCase(getProduct.rejected, (state, action) => {
      state.status["page"] = "Error";
      state.errors["page"] = action.payload as any
    });

    builder.addCase(getProduct.fulfilled, (state, action) => {

      if (typeof (action.payload) === "string") {
        state.status["page"] = "Error";
        state.errors["page"] = action.payload as any
      } else {
        state.status["page"] = "Done";
        state.items[action.meta.arg.productKey] = action.payload;
      }
    });
  },
});

export const { clearProduct, resetStatus } = productSlice.actions;

export const productSliceReducer = productSlice.reducer;
