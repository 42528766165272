import { defaultApi } from 'apis/default'
import {
  NotificationQuery,
  Notification
} from './notification.interface'
export const notification = defaultApi.injectEndpoints({
  endpoints: build => ({
    getNotifications: build.query<Notification[], NotificationQuery>({
      query: ({startRange,endRange,onlyNotReaded}) => ({
          url: `${(onlyNotReaded===undefined || onlyNotReaded===false)?`api/notify/getall/${startRange}/${endRange}`:`api/notify/getall/${startRange}/${endRange}/false`}`,
      }),
      providesTags:["Notification"]      
    }),
    readNotification: build.mutation<number, number>({
      query: (id) => ({
          url: `/api/notify/${id}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        }),
        invalidatesTags: ["Notification"],
    })
  }),
})
export const { useGetNotificationsQuery,useReadNotificationMutation
} = notification
