import { TextField, styled } from "@mui/material";
import { theme } from "..";

export const StyledTextField = styled(TextField)(() => ({
  fontFamily: theme.typography.fontFamily,
  '& .MuiInputLabel-root': {
    color: theme.palette.text.primary,
    fontSize:'16px'
  },
  "& .MuiInputBase-root": {
    color: theme.palette.text.primary,
    // borderRadius: 10,
    backgroundColor: theme.palette.background.default
  },
  "& .MuiFilledInput-input": {
    fontWeight: 700
  },
  // '& .MuiInputBase-root.Mui-focused': {
  //   outline: `${theme.palette.secondary.main} solid 1px`,
  // },
  "& .MuiFilledInput-root:before": {
    borderBottom: `2px solid ${theme.palette.common.black}`
  },
  "& .MuiFilledInput-root:after": {
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
    borderBottom: `2px solid ${theme.palette.primary.main}`
  }
}));
