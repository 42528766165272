import * as Sentry from '@sentry/react'
import { useTypedSelector } from '../hooks'
import { FC, useEffect } from 'react'
import { Navigate, Outlet, PathRouteProps, Route, RouteProps, useLocation } from 'react-router-dom'
import Loading from '../Loading'
import { GApageView } from 'utils/functions/googleAnalytics'

export type PrivateRouteProps = RouteProps & {
  unprivate?: boolean
  both?: boolean
}

const PrivateRoute: FC<React.PropsWithChildren<PrivateRouteProps>> = (props) => {

  const {
    unprivate,
    both,
    ...routeProps
  } = props
  const accessToken = useTypedSelector(({ auth }) => auth.accessToken)
  const location = useLocation()

  useEffect(() => {
    GApageView(location.pathname)
  }, [location.pathname])
  if (both) {
    return <Outlet />
  }
  else if (accessToken) { //logado
    if (unprivate) {
      return <Navigate to="/products" />
    }
    else
      return <Outlet />
  }
  else { //des-logado
    if (!unprivate)
      return <Navigate to="/login" />
    else
      return <Outlet />
  }
}

export default PrivateRoute
