import { ThemeProvider, Theme, StyledEngineProvider, Box } from "@mui/material";
import Layout from "components/Layout/Layout";
import SnackbarHandler from "components/SnackbarHandler";
import { muiTheme } from "ui-layout";
import AppRoutes from "./Routes";
import {  useTheme } from '@mui/material';
import "./App.css";


const App = () => {
  const theme = useTheme();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
          <Box
            sx={{
              backgroundColor: '#F6F6F6',
              height: "100vh",
            }}
          >
            <Layout>
              <AppRoutes />
            </Layout>
          </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
