import { FC } from 'react'
import { TextFieldProps as MUITextFieldProps } from '@mui/material'
import { StyledTextField } from './styles'
import React from 'react'

interface TextFieldProps extends Omit<MUITextFieldProps, 'variant'> {
  measuringUnit?: string
}

export const TextField: FC<TextFieldProps> = React.forwardRef(({
  children,
  measuringUnit,
  ...textFieldProps
},ref) => {
  return (
    <StyledTextField
      variant="filled"
      InputProps={{
        ...textFieldProps.InputProps,
        endAdornment: measuringUnit
      }}
      {...textFieldProps}
    >
      {children}
    </StyledTextField>
  )
})
