import React, { FC } from "react";
// import { StyledDatePicker } from "./styles";
import {
  DatePickerProps as MUIDatePickerProps,
  DesktopDatePicker as MUIDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Locale } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";

function dateFnsLocale(locale?: string): Locale {
  switch (locale) {
    case "enUS":
      return enUS;
    case "ptBR":
    default:
      return ptBR;
  }
}

// prettier-ignore
interface DesktopDatePickerProps extends MUIDatePickerProps<unknown, unknown> {
  locale?: string;
}

export const DatePicker: FC<DesktopDatePickerProps> = React.forwardRef(
  ({ locale, ...datePickerProps }, ref) => {
    return (
      // prettier-ignore
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale(locale)}>
        <MUIDatePicker
          {...datePickerProps}
        />
      </LocalizationProvider>
    );
  }
);
