import {
  AppBar, Avatar, Badge, Drawer,
  List, ListItemButton, ListItemText, ListSubheader, styled, Toolbar, useTheme
} from "@mui/material";
import { useTypedSelector } from 'hooks';
import { logout } from 'slices/auth/auth.slice';
import { Text } from "ui-layout";
import items from './nav';

import logo from "assets/img/logo-sl-horizontal.svg";
import React, { FC, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
//import minerva from 'assets/img/minerva-logo-white.png'
import { useGetNotificationsQuery } from "apis/endpoints/notification";
import { useAppDispatch } from "store";
const DefaultHeader: FC<React.PropsWithChildren<{}>> = () => {
  const theme = useTheme()
  const accessToken = useTypedSelector(({ auth }) => auth.accessToken)
  const name = useTypedSelector(({ auth }) => auth.name)
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
  }))

  const { data } = useGetNotificationsQuery({ startRange: 0, endRange: 8, onlyNotReaded: true }, { pollingInterval: 1200000,skip:!accessToken })

  const [drawerIsOpen, setdrawerIsOpen] = useState<boolean>(false)
  return (
    <>
      <AppBar
        elevation={0}
        position="sticky"
        sx={{
          height: 110,
          backdropFilter: "blur(6px)",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Toolbar>
          <RouterLink to="/">
            <img src={logo} alt="flora logo" style={{ height: 80, margin: 30 }} />
          </RouterLink>
          {accessToken && <span
            id="private-user-menu"
            title="private-user-menu"
            data-testid="private-user-menu"
            onClick={(e) => {
              setdrawerIsOpen(true)
            }}
            style={{
              cursor: 'pointer',
              display: 'flex',
              marginLeft: 'auto',
              justifyContent: 'end',
            }}
          >
            <Text variant='h4' sx={{ display: { sm: 'block', xs: 'none' } }} alignSelf={'center'} marginRight={2}>Área do Cliente</Text>
            <Text variant='body' hidden>private-user-menu</Text>
            <Badge data-testid="pendingNotification" color="success" variant="dot"
              invisible={((data || [])?.filter(x => !x.isReaded).length === 0)}
            >
              <Avatar alt={name?.toString()} sx={{ bgcolor: theme.palette.primary.main }}>
                {name?.toString().substring(0, 1).toUpperCase()}

              </Avatar>
            </Badge>
          </span >}
        </Toolbar>
      </AppBar>
      {accessToken && <Drawer
        variant="temporary"
        anchor={'right'}
        open={drawerIsOpen}
        onClose={(ev, reason) => {
          setdrawerIsOpen(false)
        }}
      >
        <List>
          <ListSubheader sx={{ margin: '40px' }}>
            <span
              onClick={(e) => {
                setdrawerIsOpen(true)
              }}
              style={{
                display: 'flex',
                marginLeft: 'auto',
                justifyContent: 'end',
              }}
            >
              <Text variant='h4' alignSelf={'center'} marginRight={2}>Bem vindo (a)</Text>
              <Badge color="success" variant="dot"
                invisible={((data || [])?.filter(x => !x.isReaded).length === 0)}
              >
                <Avatar alt={name?.toString()} sx={{ bgcolor: theme.palette.primary.main }}>
                  {name?.toString().substring(0, 1).toUpperCase()}

                </Avatar>
              </Badge>
            </span >
          </ListSubheader>
          {items
            .map((o, i) => (
              <React.Fragment key={i}>
                <ListItemButton
                  onClick={() => setdrawerIsOpen(false)}
                  sx={{ color: '#676767', margin: '10px 0', padding: '0 20px' }}
                >           <Badge color="success" variant="dot"
                  // mostrar: false  esconder: true
                  invisible={!(o.name === "Notificações" && (data || []).filter(x => !x.isReaded).length > 0)}
                >
                    <ListItemText
                      onClick={() => navigate(o.link)}
                    >{o.name}</ListItemText></Badge>
                </ListItemButton >
              </React.Fragment>
            ))}
          <React.Fragment key={'logout'}>
            <ListItemButton
              sx={{ color: '#676767', margin: '15px 0', padding: '0 20px' }}
            >
              <ListItemText onClick={() => {
                dispatch(logout())
                setdrawerIsOpen(false)
              }}>
                Sair
              </ListItemText>
            </ListItemButton >
          </React.Fragment>
        </List>
      </Drawer>}
    </>
  )
};
export default DefaultHeader;
