import Loading from "Loading";
import PrivateRoute from "components/PrivateRoute";
//import I18nInstance from "i18n";
import { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
//import { GAInitializate } from "utils/functions/googleAnalytics";
import SnackbarHandler from "./components/SnackbarHandler";
import { useTypedSelector } from "hooks";
import { GAInitializate } from "utils/functions/googleAnalytics";


const Login = lazy(() => import("pages/Login"));
const SignUp = lazy(() => import("pages/SignUp"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const RecoverPassword = lazy(() => import("pages/RecoverPassword"));
const UpdatePassword = lazy(() => import("pages/UpdatePassword"));
const Confirmation = lazy(() => import("pages/Confirmation"));
const UserAccount = lazy(() => import("pages/UserAccount"));
const ContactUs = lazy(() => import("pages/ContactUs"));
const Help = lazy(() => import("pages/Help"));
const Product = lazy(() => import("pages/Product"));
const ResultedProd = lazy(() => import("pages/ResultedProd"));
const Notification = lazy(() => import("pages/Notification"));
const History = lazy(() => import("pages/History"));

const AppRoutes = () => {
  const {  userId } = useTypedSelector(
    ({ auth }) => auth
  )

  /*  useEffect(() => {
    if (defaultLanguage && I18nInstance.language !== defaultLanguage)
      I18nInstance.changeLanguage(defaultLanguage);
  }, [defaultLanguage]); */

  /*   useEffect(() => {
    if (userId?.length) GAInitializate(userId);
  }, [userId]);
 */
  useEffect(() => {
    if (userId) GAInitializate(userId.toString())
  }, [userId])

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/login" element={<PrivateRoute unprivate path="/login" />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route  path="/confirm/:email" element={<PrivateRoute both/>}>
          <Route path="/confirm/:email" element={<Confirmation />} />
        </Route>
    {/*     <Route path="/register" element={<PrivateRoute unprivate path="/login" />}>
          <Route path="/register" element={<SignUp />} />
        </Route> */}
        <Route path="/recuperar_senha" element={<PrivateRoute unprivate />}>
          <Route path="/recuperar_senha" element={<RecoverPassword />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Dashboard />} />
        </Route>
        <Route path="/notifications" element={<PrivateRoute />}>
          <Route path="/notifications" element={<Notification />} />
        </Route>
        <Route path="/help" element={<PrivateRoute />}>
          <Route path="/help" element={<Help />} />
        </Route>
        <Route path="/contact_us" element={<PrivateRoute  />}>
          <Route path="/contact_us" element={<ContactUs />} />
        </Route>
        <Route path="/user_account" element={<PrivateRoute />}>
          <Route path="/user_account" element={<UserAccount />} />
        </Route>
        <Route path="/Dashboard" element={<PrivateRoute />}>
          <Route path="/Dashboard" element={<Dashboard />} />
        </Route>
        <Route path="/products" element={<PrivateRoute />}>
          <Route path="/products" element={<Dashboard />} />
        </Route>
        <Route path="/update_password" element={<PrivateRoute />}>
          <Route path="/update_password" element={<UpdatePassword />} />
        </Route>
        <Route path="/product/:productKey" element={<PrivateRoute/>}>
          <Route path="/product/:productKey" element={<Product/>} />
        </Route>
        <Route path="/result/:productKey" element={<PrivateRoute/>}>
          <Route path="/result/:productKey" element={<ResultedProd/>} />
        </Route>
        <Route path="/history/:productKey" element={<PrivateRoute/>}>
          <Route path="/history/:productKey" element={<History/>} />
        </Route>
      </Routes>
      <SnackbarHandler />
    </Suspense>
  );
};

export default AppRoutes;
