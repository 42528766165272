import { TableCell, TableRow, styled } from "@mui/material";
import { theme } from "..";

export const StyledTableCell = styled(TableCell)(() => ({
  "& ": {
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.common.black}`
  },
  "&:last-child": {
    border: 0
  }
}));

export const StyledTableRow = styled(TableRow)(() => ({

}));
