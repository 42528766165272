import { Button, styled } from '@mui/material'
import { theme } from '..'

const BaseButton = styled(Button)(() => ({
  fontFamily: theme.typography.fontFamily,
  borderRadius:'19px',
  fontWeight: 600,
  ':disabled': {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.background.disabled,
    border: '2px solid',
    borderColor: theme.palette.background.disabled,
  },
}))

export const PrimaryOutlinedButton = styled(BaseButton)(() => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  border: '2px solid',
  ':hover': {
    backgroundColor: theme.palette.primary.main,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  ':focus': {
    backgroundColor: theme.palette.primary.main,
    border: '2px solid',
    borderColor: theme.palette.accent,
    color: theme.palette.primary.contrastText,
  },
}))

export const PrimaryContainedButton = styled(BaseButton)(() => ({
  backgroundColor: theme.palette.primary.main,
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  ':hover': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: 'transparent',
    border: '2px solid',
  },
  ':focus': {
    backgroundColor: theme.palette.accent,
    border: '2px solid',
    borderColor: theme.palette.accent,
    color: theme.palette.primary.contrastText,
  },
}))

export const SecondaryOutlinedButton = styled(BaseButton)(() => ({
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
  border: '2px solid',
  ':hover': {
    backgroundColor: theme.palette.secondary.main,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  ':focus': {
    backgroundColor: theme.palette.primary.main,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
  },
}))

export const SecondaryContainedButton = styled(BaseButton)(() => ({
  backgroundColor: theme.palette.secondary.main,
  border: '2px solid',
  borderColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  ':hover': {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: '2px solid',
  },
  ':focus': {
    backgroundColor: theme.palette.primary.main,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
  },
}))

export const PrimaryTextButton = styled(BaseButton)(() => ({
  color: theme.palette.primary.main,
  ':focus': {
    color: theme.palette.accent,
  },
}))

export const SecondaryTextButton = styled(BaseButton)(() => ({
  color: theme.palette.secondary.main,
  ':focus': {
    color: theme.palette.primary.main,
  },
}))

export const PrimarySoftButton = styled(BaseButton)(() => ({
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.text.disabled}`,
  ':hover': {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  ':focus': {
    backgroundColor: theme.palette.accent,
    border: '1px solid',
    borderColor: theme.palette.accent,
    color: theme.palette.primary.contrastText,
  },
}))

export const SecondarySoftButton = styled(BaseButton)(() => ({
  color: theme.palette.secondary.main,
  border: `1px solid ${theme.palette.text.disabled}`,
  ':hover': {
    backgroundColor: theme.palette.secondary.main,
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  ':focus': {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}))
