import React, { FC } from "react";
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  CircularProgress,
  Link
} from "@mui/material";

import {
  PrimaryContainedButton,
  PrimaryOutlinedButton,
  PrimarySoftButton,
  PrimaryTextButton,
  SecondaryContainedButton,
  SecondaryOutlinedButton,
  SecondarySoftButton,
  SecondaryTextButton
} from "./styles";
import { StyledComponent } from "@emotion/styled";

interface ButtonProps extends Omit<MUIButtonProps, "variant"> {
  color: "primary" | "secondary";
  variant: "contained" | "outlined" | "text" | "soft";
  isLoading?: boolean
}

const StyledButtonsDictionary = {
  primary_outlined: PrimaryOutlinedButton,
  primary_contained: PrimaryContainedButton,
  secondary_outlined: SecondaryOutlinedButton,
  secondary_contained: SecondaryContainedButton,
  primary_text: PrimaryTextButton,
  secondary_text: SecondaryTextButton,
  primary_soft: PrimarySoftButton,
  secondary_soft: SecondarySoftButton
} as Record<string, StyledComponent<MUIButtonProps>>;

const getStyledComponent: (
  props: ButtonProps
) => StyledComponent<MUIButtonProps> = ({ color, variant }) =>
  StyledButtonsDictionary[`${color}_${variant}`] ?? MUIButton;

export const Button: FC<ButtonProps> = React.forwardRef(({
  children,
  variant,
  isLoading,
  color,
  ...buttonProps
},ref) => {
  const StyledButton = getStyledComponent({ color, variant });

  return (
    <StyledButton  {...buttonProps} sx={{...buttonProps.sx,textTransform:'none'}} >
    
      {isLoading ? (
            <CircularProgress data-testid="loadingIcon" color="info" size={24} />
      ):children}
    </StyledButton>
  );
});
