import { AxiosRequestConfig,InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'
import store from '../store'
import isValidToken from './isValidToken'

const configAuthorizationHeader = async (config: InternalAxiosRequestConfig) => {
  let token = store.getState().auth?.accessToken
  const tokenPayload = store.getState().auth?.tokenPayload

  if (!tokenPayload) return config


  if (config.headers) config.headers['Authorization'] = `Bearer ${token}`

  return config
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.request.use(configAuthorizationHeader)
export const apiFlora2 = axios.create({
  baseURL: process.env.REACT_APP_API_TOKEN_URL,
})


export default api