import { filledInputClasses, Select, styled } from '@mui/material'

import { theme } from '..'
const BaseSelect = styled(Select)(() => ({
  fontFamily: theme.typography.fontFamily,
  color: 'white',
  borderRadius: '6px',
  backgroundColor: '#434843',
  fontWeight: '700',
  fontSize: '14px',
  '& .MuiSelect-select': {
    display: 'flex',
  },
  ':hover .MuiFilledInput-root': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: 'white'
  },
  [`&.${filledInputClasses.input}`]: {
    paddingTop: '13px',
  },
  [`&.${filledInputClasses.focused} .${filledInputClasses.input}`]: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '6px',
    [`& .${filledInputClasses.root}`]: {
      border: 'none',
    }
  },
  ':after': {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '6px',
  }
}))
export const FilledSelect = styled(BaseSelect)({
  [`&.${filledInputClasses.root}`]: {
    backgroundColor: '#434843',
  }
})
export const ComposedSelect = styled(BaseSelect)({
  color: '#FFFFFF',
  '& .MuiSelect-select': {
    display: 'flex',
    color: '#FFFFFF'
  },
  [`&.${filledInputClasses.root}`]: {
    backgroundColor: '#191919',
  }
})
