import { createSlice } from '@reduxjs/toolkit'



const initialState: Record<string, string[]> = {}

const { reducer, actions } = createSlice({
  name: 'cars',
  initialState: initialState,
  reducers: {
    setCarList: (state, action) => {
      return(action.payload)},
    clearCar: () => initialState
  }
})

export default reducer
export const { setCarList, clearCar } = actions
