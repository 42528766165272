import { Typography, styled } from '@mui/material'
import { theme } from '..'

export const BaseTypography = styled(Typography)(() => ({
  fontFamily: theme.typography.fontFamily
}))

export const HeaderText1 = styled(BaseTypography)(() => ({
  fontSize: '1.6rem',
  fontWeight: 700,
}))
export const HeaderText2 = styled(BaseTypography)(() => ({
  fontSize: '2rem',
  fontWeight: 500,
  margin:'10px 0'
}))
export const HeaderText3 = styled(BaseTypography)(() => ({
  fontSize: '1.4rem',
  fontWeight: 700,
}))
export const HeaderText4 = styled(BaseTypography)(() => ({
  fontSize: '1.6rem',
  fontWeight: 400,
}))
export const HeaderText5 = styled(BaseTypography)(() => ({
  fontSize: '1.2rem',
  fontWeight: 400,
}))
export const HeaderText6 = styled(BaseTypography)(() => ({
  fontSize: '1.1rem',
  fontWeight: 300,
}))
export const BodyText = styled(BaseTypography)(() => ({
  // background: theme.palette.background.default,
  color: theme.palette.text.secondary,
  fontSize: '1rem',
}))