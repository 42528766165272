import { Action, combineReducers } from '@reduxjs/toolkit'
import { defaultApi } from 'apis/default'
import authSlice from 'slices/auth/auth.slice'
import layoutSlices from 'slices/layout/layout.slices'
import snackbarSlice from 'slices/snackbar/snackbarSlice'
import carSlice from 'slices/car/carSlice'
import { productSliceReducer } from 'slices/Product/product.slice'

// usar ordem alfabética
const reducers = combineReducers({
  [defaultApi.reducerPath]: defaultApi.reducer,
  auth: authSlice,
  car: carSlice,
  layout: layoutSlices,
  product: productSliceReducer,
  snackbar: snackbarSlice,
});

const rootReducers = (state: any, action: Action) => {
  if (action.type === "auth/logout") {
    state = undefined
  };
  return reducers(state, action);
};

export default rootReducers;
