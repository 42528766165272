import { Middleware } from '@reduxjs/toolkit'
import { setSnackbar } from './slices/snackbar/snackbarSlice'
import { logout } from 'slices/auth/auth.slice'

const mid: Middleware = (store) => (next) => (action) => {
  if (action?.error && action?.error.name !== 'ConditionError') {
    const mssg = action?.payload?.status === 401? 'common:expiredSession' : action?.error?.message ?? 'common:error' 
    store.dispatch(
      setSnackbar({
        type: 'error',
        message: mssg,
        duration: 4000
      })
    )
  }
  return next(action)
}

export default mid
