import { Layout } from './layout.interfaces'
import { createSelector } from 'utils/functions'
import { createSlice } from '@reduxjs/toolkit'

const initialState: Layout = {
  current: { type: 'none' }
}

const { reducer, actions } = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    chooseLayout(state, action) {
      state.current = action.payload
    }
  }
})

export const selectLayout = createSelector(({ layout }) => layout.current)

export const { chooseLayout } = actions

export default reducer
