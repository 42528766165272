import { useTypedSelector } from "hooks";
import { ReactNode } from "react";
import { selectLayout } from "slices/layout/layout.slices";
import DefaultContainer from "../DefaultContainer/DefaultContainer";
import { Box, Grid, Typography, useTheme } from "@mui/material";
//import { useParams } from "react-router-dom";

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const layout = useTypedSelector(selectLayout)

  return (
    <DefaultContainer>
      {children as any}
  </DefaultContainer>
  );
};

export default Layout;
