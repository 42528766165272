import { Middleware } from '@reduxjs/toolkit'
import { GAevent } from 'utils/functions/googleAnalytics'

const googleAnalyticsMiddleware: Middleware = () => (next) => (action) => {
  const actionType = action?.type.split('/')
  if (actionType[2] === 'fulfilled') {
    if (actionType[1] === 'getProduct') {
      {
        GAevent('Produto requisitado', action?.meta?.arg.productKey, JSON.stringify(action?.meta?.arg || ''))
      }
    }
  }

  return next(action)
}

export default googleAnalyticsMiddleware
