import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableProps as MUITableProps,
} from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledTableCell, StyledTableRow } from "./styles";
import {
  CircularProgress
} from "@mui/material";
interface TableProps extends MUITableProps {
  rows: any;
  columns: any;
  isLoading: boolean;
}

export const TableBase: FC<TableProps> = React.forwardRef(
  ({ children, ...tableProps }, ref) => {
    const { t } = useTranslation("products");
    const { rows, columns,isLoading} = tableProps;
    //prettier-ignore
    const fieldsLabels = [...new Set(rows.map((row: any) => Object.keys(row)).flat())];
    fieldsLabels.shift(); // recebe as colunas com 'id' como a primeira coluna shift() remove ela

    const [page, setPage] = useState(0);
    const [rowsPerPage, setrowsPerPage] = useState(10);
    //prettier-ignore
    const handlePageChange = (event: any, newPage: React.SetStateAction<number>) => {
      setPage(newPage);
    };
    //prettier-ignore
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setrowsPerPage(+event.target.value);
      setPage(0);
    };
    //prettier-ignore
    const pagedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const labelDisplayedRows = ({ from, to, count }) => {
      const totalPages = Math.ceil(count / rowsPerPage);
      return `${page + 1} - ${totalPages}`;
    };

    return (
      <TableContainer sx={{ maxHeight: 700, maxWidth: "90vw" }}>
        <Table stickyHeader size="small" aria-label="base-table">
          <TableHead>
            <StyledTableRow>
              {columns.map(({ field, width }, index) => (
                <StyledTableCell key={index} width={width}>
                  {field}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          {isLoading ? <TableBody><CircularProgress data-testid="loadingIcon" color="info" size={24} /></TableBody> :
            <TableBody>
              {rows.length ? (
                pagedRows.map((row, index) => (
                  <StyledTableRow key={index}>
                    {fieldsLabels.map((fieldLabel, index) => (
                      <StyledTableCell key={index}>
                        {row[`${fieldLabel}`]}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={columns.length} align="center">
                    {t("products:noRecordsFound")}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>}
        </Table>
        <TablePagination
          component="div"
          count={rows.length}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("products:registersPerPage")}
          labelDisplayedRows={labelDisplayedRows}
          showFirstButton
          showLastButton
          sx={{ display: "flex", justifyContent: "center", border: 0 }}
        />
      </TableContainer>
    );
  }
);
