import { Container, useTheme } from "@mui/material";
import React, { FC } from "react";

import { useTypedSelector } from "hooks";
import { useDispatch } from "react-redux";
import { BreadcrumbLayoutOptions } from "slices/layout/layout.interfaces";
import DefaultHeader from "./DefaultHeader";

const DefaultContainer: FC<
  React.PropsWithChildren<{
    children: NonNullable<React.ReactNode>;
  }>
> = (props) => {
  const theme = useTheme();
  return (
    <>
      <DefaultHeader />
          <Container maxWidth={false} sx={{
            backgroundColor: theme.palette.background.default,
          }}>
            {props.children}
      </Container>
    </>
  );
};

export default DefaultContainer;
