import { Assessment, Dashboard, FolderOpenOutlined } from '@mui/icons-material'
import {
  //Agrometeorology,
  Areas,
  //Assignment,
  PropertyIcon,
  Sustainability,
  Reports,
  Layers,
  Simulator,
  Analysis,
} from 'assets/img/icons/outraCoisaIcons'
import { AuthState } from 'slices/auth/auth.interfaces'
export interface NavObj {
  name: string
  disabled?: boolean
  link: string
  subItems?: Array<NavObj>
  condition?: (data: any) => boolean
}

const nav: NavObj[] = [
  {
    name: 'Minha conta',
    link: '/user_account',
  },
  {
    name: 'Meus produtos',
    link: '/products',
  },
  {
    name: 'Notificações',
    link: '/notifications',
  },
  {
    name: 'Ajuda',
    link: '/help',
  },
  {
    name: 'Fale conosco',
    link: '/contact_us',
  },
]

export default nav
