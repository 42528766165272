import i18n, { Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-locize-backend'

const locizeOptions = {
  projectId: '8e09c3a8-9fc2-462b-956c-0c32c478a864',
  apiKey: '22235e42-4330-48bf-980f-7a586fab61ce',
}

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    partialBundledLanguages: true,
    fallbackLng: 'pt-BR',
    supportedLngs: ['pt-BR'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: locizeOptions,
  })
export default i18n
