import { RadioGroup, styled } from '@mui/material'
import { theme } from 'ui-layout/theme'

const BaseRadio = styled(RadioGroup)(() => ({
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontSize: '16px',
    '& .MuiRadio-root': {
        color: theme.palette.info.light,
    }
}))

export const PrimaryRadio = styled(BaseRadio)(() => ({
    '& .MuiRadio-root.Mui-checked': {
        color: theme.palette.primary.main,
    }
}))
export const SecondaryRadio = styled(BaseRadio)(() => ({
    '& .MuiRadio-root.Mui-checked': {
        color: theme.palette.secondary.main,
    }
}))